<template>
  <div class="checkout-view">
    <div ref="anchor"></div>
    <div
      class="checkout-nav"
      :class="{ 'centered-logo': step === 'confirmation' }"
    >
      <img
        class="nav-logo"
        src="@/assets/spartan_logo.svg"
        alt="Spartan"
        @click="navigateHome"
      />
      <div class="nav-controls" v-if="step !== 'confirmation'">
        <div
          class="nav-link"
          :class="{ 'is-active': step === 'details' }"
          @click="goToDetailsStep"
        >
          {{ $t("details") }}
        </div>
        <i class="material-icons">arrow_forward_ios</i>
        <template v-if="showExtrasStep">
          <div
            class="nav-link"
            :class="{ 'is-active': step === 'extras' }"
            @click="navigateExtrasStep"
          >
            {{ $t("extras") }}
          </div>
          <i class="material-icons">arrow_forward_ios</i>
        </template>
        <div class="nav-link" :class="{ 'is-active': step === 'payment' }">
          {{ $t("payment") }}
        </div>
      </div>
    </div>
    <transition name="slidedown">
      <notification
        sticky
        background-color="#F4F4F4"
        v-if="toolbarNotification.isVisible"
      />
    </transition>

    <template>
      <template v-if="step === 'confirmation'">
        <step-confirmation
          :show-discount="showDiscount"
          @gtm-push="pushDataLayerEvent"
        />
      </template>
      <template v-else>
        <!-- mobile header -->
        <div
          class="mobile-header"
          :class="{
            'is-collapsed': mobileMenu,
            'is-disabled': orderDetails && orderDetails.total < 0,
          }"
          @click="mobileMenu = !mobileMenu"
        >
          <div>{{ $t("summary") }}</div>
          <template>
            <div
              v-if="orderDetails.total || orderDetails.total === 0"
              class="collapse-button"
            >
              <div v-if="!mobileMenu" class="cart-total">
                <span v-if="showDiscount" class="discount-amount">{{
                  orderDetails.originalTotal | currency
                }}</span>
                <span>{{ orderDetails.total | currency }}</span>
              </div>

              <i class="material-icons">arrow_drop_down</i>
            </div>
            <div v-else class="skeleton-line"></div>
          </template>
        </div>
        <!-- mobile header dropdown overlay -->
        <div
          class="dropdown-overlay"
          v-if="mobileMenu"
          @click="mobileMenu = false"
        ></div>
        <!-- mobile header dropdown -->
        <div class="dropdown-wrap" v-if="mobileMenu">
          <div class="dropdown-body">
            <price-decomposition />
          </div>
          <div class="dropdown-footer">
            <div class="line-discount">
              <span v-if="showDiscount">{{
                orderDetails.originalTotal | currency
              }}</span>
            </div>
            <div class="line-total">
              <span>{{ $t("cart_total_title") }}</span>
              <span>{{ orderDetails.total | currency }}</span>
            </div>
            <div class="line-vat">
              <span v-if="orderDetails.vat"
                >{{ $t("including") }} VAT
                {{ orderDetails.vat | currency }}</span
              >
            </div>
          </div>
        </div>

        <!-- promo code field (mobile) -->
        <div class="promo-code-section">
          <promo-code
            v-if="showPromoCode"
            :checkout-processing="processing"
            :is-loading="codeApplying"
            :errors="codeApplyingErrors"
            @apply-code="onCodeApply"
            @delete-code="onCodeDelete"
          />
          <div v-else class="show-code-button" @click="showPromoCode = true">
            <span>{{ $t("add_promocode") }}</span>
          </div>
        </div>

        <div class="checkout-body">
          <div class="checkout-mobile-nav">
            <div class="nav-controls">
              <div
                class="nav-link"
                :class="{ 'is-active': step === 'details' }"
                @click="goToDetailsStep"
              >
                {{ $t("details") }}
              </div>
              <i class="material-icons">arrow_forward_ios</i>
              <template v-if="showExtrasStep">
                <div
                  class="nav-link"
                  :class="{ 'is-active': step === 'extras' }"
                  @click="navigateExtrasStep"
                >
                  {{ $t("extras") }}
                </div>
                <i class="material-icons">arrow_forward_ios</i>
              </template>
              <div
                class="nav-link"
                :class="{ 'is-active': step === 'payment' }"
              >
                {{ $t("payment") }}
              </div>
            </div>
          </div>
          <component
            class="step-component"
            :is="currentStep"
            :validate="validateDetails"
            :submitPayment="submitPayment"
            :country="country"
            :redirectUrl="redirectUrl"
            :killerDeal="killerDeal"
            :paymentProcessor="paymentProcessor"
            :is-loaded="isLoadedOrder"
            :stripeConfig="stripeConfig"
            @change-step="onStepChange"
            @on-error="showErrorDialog"
            @gtm-push="pushDataLayerEvent"
            @on-details-change="getPriceDecomposition"
            @on-payment-method-change="setPaymentMethod"
            @set-process-status="onProcessStatusChange"
          >
            <template #refundProtect>
              <refund-protect-widget
                v-if="runRefundProtectComponent"
                :checkoutStep="step"
                :updateRefundProtectQuote="updateRefundProtectQuote"
                @set-process-status="onProcessStatusChange"
                @post-checkout-order="postProtectedOrder"
              />
            </template>
          </component>

          <div
            v-if="step === 'payment'"
            class="payment-agreement-mob"
            :class="{ 'has-error': errors.paymentAgreement }"
          >
            <mem-checkbox
              v-model="paymentCheckbox"
              theme="light"
              :has-error="errors.paymentAgreement"
            />
            <div class="agreement-text" v-html="paymentCheckboxText" />
          </div>
          <div class="sticky-card" v-if="!mobileView">
            <price-card
              :step="step"
              :processing="processing"
              :show-discount="showDiscount"
              :killerDeal="killerDeal"
              @on-submit="validateStep"
            >
              <template #agreementCheckbox>
                <div v-if="step === 'payment'">
                  <div
                    class="payment-agreement"
                    :class="{ 'has-error': errors.paymentAgreement }"
                  >
                    <mem-checkbox
                      v-model="paymentCheckbox"
                      theme="light"
                      :has-error="errors.paymentAgreement"
                    />
                    <div class="agreement-text" v-html="paymentCheckboxText" />
                  </div>
                </div>
              </template>
              <template #promoCodeField>
                <promo-code
                  class="promo-code-slot"
                  :checkout-processing="processing"
                  :is-loading="codeApplying"
                  :errors="codeApplyingErrors"
                  @apply-code="onCodeApply"
                  @delete-code="onCodeDelete"
                />
              </template>
              <template #cardButton>
                <checkout-button
                  :step="step"
                  :loading="processing"
                  :paymentCheckbox="paymentCheckbox"
                  :killerDeal="killerDeal"
                  :sezzlePayment="sezzlePayment"
                  @on-sezzle-init="onSezzleCheckout"
                  @on-sezzle-complete="submitSezzlePayment"
                  @on-sezzle-error="showErrorDialog"
                  @on-submit="validateStep"
                />
              </template>
            </price-card>
          </div>
        </div>

        <div
          class="checkout-policy"
          v-if="checkoutParams.refundPolicy && step === 'details'"
        >
          <div>{{ $t("refund_and_transfer_policy") }}</div>
          <v-tooltip
            v-model="policyTooltip"
            top
            :open-on-hover="false"
            :max-width="320"
            content-class="mem-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="material-icons" v-bind="attrs" v-on="on">
                info
              </span>
            </template>
            <div class="policy-tooltip">
              <span
                class="material-icons tooltip-close"
                @click="policyTooltip = false"
              >
                close
              </span>
              <!-- <span class="tooltip-title">{{
              $t("refund_and_transfer_policy")
            }}</span> -->
              <div
                class="tooltip-body"
                v-html="checkoutParams.refundPolicy"
              ></div>
            </div>
          </v-tooltip>
        </div>

        <div class="checkout-controls" v-if="mobileView">
          <checkout-button
            :step="step"
            :loading="processing"
            :paymentCheckbox="paymentCheckbox"
            :killerDeal="killerDeal"
            :sezzlePayment="sezzlePayment"
            @on-sezzle-init="onSezzleCheckout"
            @on-sezzle-complete="submitSezzlePayment"
            @on-sezzle-error="showErrorDialog"
            @on-submit="validateStep"
          />
        </div>
      </template>
    </template>
    <error-dialog
      v-model="errorDialog.show"
      :title="errorDialog.title"
      :subline="errorDialog.subline"
      :source="errorDialog.source"
      @on-refresh-confirmation="retryRequest"
    />
  </div>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";
// import config from "@/config";
import { CHECKOUT_CONFIG } from "@/lists";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { lowerCase, map, isEmpty, toNumber, some } from "lodash";

import StepDetails from "./StepDetails.vue";
import StepExtras from "./StepExtras.vue";
import StepPayment from "./StepPayment.vue";
export default {
  components: {
    "price-card": () => import("./PriceCard.vue"),
    "price-decomposition": () => import("./PriceDecomposition.vue"),
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "checkout-button": () => import("./CheckoutButton.vue"),
    "step-confirmation": () => import("./StepConfirmation.vue"),
    "error-dialog": () => import("./DialogCheckoutError.vue"),
    notification: () => import("@/components/TheToolbarNotification.vue"),
    "mem-checkbox": () => import("@/components/base/BaseCheckbox.vue"),
    "promo-code": () => import("./PromoCode.vue"),
    "refund-protect-widget": () => import("./TheRefundProtect.vue"),
  },
  data: () => ({
    env: process.env.VUE_APP_ENV,
    mobileMenu: false,
    step: "details",
    paramsTickets: [],
    // components
    processing: false,
    isLoadedOrder: false,
    // Promo code
    codeApplying: false,
    codeApplyingErrors: [],

    validateDetails: false,
    submitPayment: false,
    updateRefundProtectQuote: false,
    initRefundProtect: false,

    policyTooltip: null,
    paymentCheckbox: false,
    sezzlePayment: false,
    mobileView: false,
    errors: {
      paymentAgreement: false,
    },
    // dialog
    errorDialog: {
      show: false,
      title: "",
      subline: null,
      source: null,
    },
    // mobile view
    showPromoCode: false,
  }),
  computed: {
    ...mapGetters([
      "checkoutList",
      "orderDetails",
      "storedTickets",
      "userLocaleTranslationsLoaded",
      "i18nLoadedList",
      "checkoutParams",
      "toolbarNotification",
      "gtmConversionData",
      "refoundProtectData",
      "checkoutPromoCode",
      "spartanFoundationQuestion",
    ]),
    currentStep() {
      if (this.step === "extras") return StepExtras;
      if (this.step === "payment") return StepPayment;
      return StepDetails;
    },
    country() {
      return lowerCase(this.$route.params.region);
    },
    stripeConfig() {
      // Get Stripe data from TS response
      let { stripe } = this.checkoutParams;
      // Get Stripe data from localStorage
      if (!stripe && localStorage.getItem("checkoutStripeData")) {
        return JSON.parse(localStorage.getItem("checkoutStripeData"));
      }

      // This is bad :(
      if (!stripe) return {};

      return stripe;
      // return config.stripe.keys[this.$route.params.region];
    },
    paymentCheckboxText() {
      return localStorage.getItem("memPaymentCheckbox");
    },
    redirectUrl() {
      return `${location.origin}/${this.country}/checkout`;
    },
    showDiscount() {
      return this.orderDetails.total !== this.orderDetails.originalTotal;
    },
    killerDeal() {
      // What is this? Well... In that case, checkout flow completely changes
      // 1. When step Details changes to Payment - оrder not creating (onStepChange);
      // 2. Payment step:
      //   a. Not shows payment methods & Stripe payment form;
      //   b. On applying 100% discount - order not creating (onCodeApplied);
      // 3. (postOrder) will shoot after (validateStep) on Payment step;
      return this.orderDetails.total === 0;
    },
    nextStep() {
      if (this.step === "details" && !this.showExtrasStep) return "payment";

      if (this.step === "details") return "extras";
      if (this.step === "extras") return "payment";
      if (this.step === "payment") return "confirmation";

      return "details";
    },
    refundProtectAvailable() {
      if (this.killerDeal) return false;
      //Global settings
      if (!this.checkoutParams?.refundProtectActive) return false;

      let nonRefundableTickets = some(this.checkoutList, (ticket) => {
        return ticket.event?.refund_protect_disable;
      });
      if (nonRefundableTickets) return false;
      return true;
    },
    runRefundProtectComponent() {
      if (!this.refundProtectAvailable) return;
      if (this.step === "extras") return true;

      if (
        this.step === "payment" &&
        this.initRefundProtect &&
        this.refoundProtectData.protectionSelected
      )
        return true;

      return false;
    },
    showExtrasStep() {
      if (this.refundProtectAvailable) return true;
      if (!isEmpty(this.spartanFoundationQuestion)) return true;
      if (!isEmpty(CHECKOUT_CONFIG[this.country]?.fundraisingCompanies))
        return true;

      return false;
    },
    paymentProcessor() {
      if (this.country === "in") return "PayU";
      return "Stripe";
    },
  },
  methods: {
    ...mapActions([
      "getCart",
      "getOrderDetails",
      "getOrderDescribe",
      "postOrder",
      "completeOrder",
      "updateTicketsList",
      "handleToolbarNotification",
      "updateCartDetails",
      "validatePromoCode",
      "confirmPayuTransaction",
    ]),
    ...mapMutations([
      "setCheckoutRegion",
      "setPromoCodeStatus",
      "deletePromoCode",
      "setRefunfProtectData",
    ]),
    navigateHome() {
      if (this.env === "production") return;
      this.$router.push({ name: "home" });
    },
    async goToDetailsStep() {
      if (this.step === "details") return;
      this.isLoadedOrder = false;
      this.$router.push({ name: "checkout", query: {} }).catch(() => {});
      this.step = "details";
      this.scrollOnTop();

      try {
        await this.getOrderDetails();
        this.isLoadedOrder = true;
        await this.getOrderDescribe();
      } catch (error) {
        this.showErrorDialog(error);
      }
    },
    async navigateExtrasStep() {
      this.initRefundProtect = false;
      if (this.step === "details" || this.step === "extras") return;
      if (this.refundProtectAvailable) await this.getOrderDescribe();
      this.$router.push({ name: "checkout", query: {} }).catch(() => {});
      this.step = "extras";
      this.scrollOnTop();
    },
    // TODO: rename this function
    async validateStep() {
      if (this.processing) return;
      // Run validation in StepDetails component
      if (this.step === "details") {
        this.validateDetails = true;
        this.$nextTick(() => {
          this.validateDetails = false;
        });
        return;
      }
      // ---- Payment ----
      if (this.step === "payment") {
        // validate
        if (!this.paymentCheckbox) {
          this.errors.paymentAgreement = true;
          return;
        }
        this.errors.paymentAgreement = false;
        this.submitPayment = !this.submitPayment;
      }

      if (this.step === "extras") {
        return await this.onStepChange();
      }
    },
    async generateTicketsList() {
      console.log("Generate tickets list...");

      // TODO: Remove this function call
      await this.getCart();

      let ticketsKeys = [];
      let params = this.$route.query;
      for (const [key, value] of Object.entries(params)) {
        if (key.includes("cartTasks") && key.includes("typeId")) {
          ticketsKeys.push(value);
        }
      }
      this.paramsTickets = map(ticketsKeys, (key) => {
        return {
          //cartTasks[ticketTypeId_][typeId]
          ts_ticket_type_id: params[`cartTasks[ticketTypeId_${key}][typeId]`],
          //cartTasks[ticketTypeId_][eventId]
          ts_event_id: params[`cartTasks[ticketTypeId_${key}][eventId]`],
          //cartTasks[ticketTypeId_][quantity]
          quantity: toNumber(
            params[`cartTasks[ticketTypeId_${key}][quantity]`]
          ),
          country: this.country?.toUpperCase(),
          //
          ts_id: `${this.country?.toUpperCase()}${
            params[`cartTasks[ticketTypeId_${key}][typeId]`]
          }`,
        };
      });

      if (!isEmpty(this.paramsTickets))
        await this.updateTicketsList(this.paramsTickets);
    },
    async onStepChange() {
      // save selections
      console.log("Saving selections...");
      if (this.nextStep !== "confirmation") await this.updateCartDetails();

      if (this.nextStep !== "payment") {
        this.step = this.nextStep;
        this.scrollOnTop();
        return;
      }
      // payment step set up
      if (this.killerDeal) {
        this.step = this.nextStep;
        this.pushDataLayerEvent("Payment");
        this.scrollOnTop();
        return;
      }
      this.processing = true;
      try {
        await this.postOrder({
          canRetry: true,
          paymentProcessor: this.paymentProcessor,
          redirectUrl: this.redirectUrl,
        });
        this.processing = false;
        this.step = this.nextStep;
        this.pushDataLayerEvent("Payment");
        this.scrollOnTop();
      } catch (error) {
        this.processing = false;
        this.showErrorDialog(error);
      }
    },
    onProcessStatusChange(status) {
      this.processing = status;
    },
    async onCodeApply() {
      if (this.codeApplying) return;

      this.codeApplyingErrors = [];
      if (!this.checkoutPromoCode.value)
        return this.codeApplyingErrors.push(this.$t("field_required"));
      this.showPromoCode = true; // ?
      this.processing = true;
      // New flow
      this.codeApplying = true;
      try {
        const code = await this.validatePromoCode();
        this.setPromoCodeStatus(code.valid);

        if (!code.valid) {
          this.processing = false;
          this.codeApplying = false;
          return this.codeApplyingErrors.push(
            this.$t("cart_promo_code_invalid_code")
          );
        }

        // Checking if code works in this order
        const orderDescribe = await this.getOrderDescribe();
        let { total, originalTotal } = orderDescribe;

        if (total === originalTotal && total !== 0 && originalTotal !== 0) {
          this.processing = false;
          this.codeApplying = false;
          this.setPromoCodeStatus(false);
          this.codeApplyingErrors.push(
            this.$t("cart_promo_code_not_applicable")
          );
          return;
        }
        // Save code to localStorage
        // Using in:
        // 1. gtmConversionData getter
        // 2. getOrderDescribe action
        localStorage.setItem(
          "checkoutCoupon",
          JSON.stringify({ code: this.checkoutPromoCode.value })
        );

        // details step && payment step killerDeal case
        if (this.step === "details" || this.killerDeal) {
          this.processing = false;
          this.codeApplying = false;
          return;
        }
        // extras step
        if (this.step === "extras") {
          if (!orderDescribe && this.refundProtectAvailable)
            this.handleRefundProtect();

          this.processing = false;
          this.codeApplying = false;
          return;
        }
        // payment step
        // refund protect case
        if (
          this.refundProtectAvailable &&
          this.refoundProtectData.protectionSelected
        ) {
          this.initRefundProtect = true;
          this.handleRefundProtect();
          return;
        }

        await this.postOrder({
          canRetry: false,
          paymentProcessor: this.paymentProcessor,
          redirectUrl: this.redirectUrl,
        });
        this.processing = false;
        this.codeApplying = false;
      } catch (error) {
        console.log("Apply promo code error", error);
        localStorage.removeItem("checkoutCoupon");
        this.setPromoCodeStatus(false);
        await this.getOrderDescribe();
        this.processing = false;
        this.codeApplying = false;

        // validatePromoCode
        // getOrderDescribe
        // postOrder
        let { source } = error;
        if (!source || source === "post-order-non-retryable")
          return this.showErrorDialog({
            title: "cart_promo_code_order_error_title",
            message: "cart_promo_code_order_error_text",
          });
        this.showErrorDialog(error);
      }
    },
    async onCodeDelete() {
      try {
        this.processing = true;
        this.codeApplying = true;
        if (this.step === "payment") {
          this.setPromoCodeStatus(false);
          await this.postOrder({
            canRetry: false,
            paymentProcessor: this.paymentProcessor,
            redirectUrl: this.redirectUrl,
          });
        }
        this.deletePromoCode();
        await this.getPriceDecomposition();
        this.processing = false;
        this.codeApplying = false;
      } catch (error) {
        console.log("Promo code delete error", error);

        this.setPromoCodeStatus(true);
        this.processing = false;
        this.codeApplying = false;
        let { source } = error;
        if (!source || source === "post-order-non-retryable")
          return this.showErrorDialog({
            title: "cart_promo_code_order_error_title",
            message: "cart_promo_code_order_error_text",
          });
        this.showErrorDialog(error);
      }
    },
    showErrorDialog(error) {
      console.log("Checkout error", error);
      let { title, message, source } = error;

      if (!title)
        return this.handleToolbarNotification({
          type: "warning",
          text: "checkout_error_server_overloaded",
          isVisible: true,
          canClose: true,
        });

      this.errorDialog.title = title;
      if (message) this.errorDialog.subline = message;
      if (source) this.errorDialog.source = source;

      this.errorDialog.show = true;
    },
    async getPriceDecomposition() {
      this.processing = true;
      try {
        await this.getOrderDescribe();
        this.processing = false;
        if (this.refundProtectAvailable) this.handleRefundProtect();
      } catch (error) {
        this.showErrorDialog(error);
        this.processing = false;
      }
    },
    async retryRequest(requestName) {
      console.log("request name", requestName);
      // TODO: Perhaps, move all actions to this view

      try {
        if (requestName === "get-order") {
          this.isLoadedOrder = false;
          await this.getOrderDetails();
          this.isLoadedOrder = true;
        }
        if (requestName === "get-order-describe") await this.getOrderDescribe();
        if (requestName === "post-order")
          await this.postOrder({
            canRetry: true,
            paymentProcessor: this.paymentProcessor,
            redirectUrl: this.redirectUrl,
          });
      } catch (error) {
        this.showErrorDialog(error);
      }
    },
    scrollOnTop() {
      const options = { behavior: "smooth" };
      // (Safari issue) For some reasons not work in Safari browsers
      // setTimeout helps with that
      setTimeout(() => {
        this.$refs.anchor.scrollIntoView(options);
      }, 0);
    },
    pushDataLayerEvent(event) {
      console.log(
        `%cData Layer PUSH! Event: ${event}`,
        "color: pink",
        "🌈🌚 🌝💨",
        this.gtmConversionData
      );
      if (!window.dataLayer) return;
      if (event === "Conversion")
        return window.dataLayer.push({ event, ...this.gtmConversionData });

      return window.dataLayer.push({ event });
    },
    setPaymentMethod(paymentMethod) {
      console.log("Set payment method", paymentMethod);

      if (paymentMethod === "sezzle") {
        this.sezzlePayment = true;
        return;
      }
      this.sezzlePayment = false;
    },
    onScreenSizeChange() {
      if (window.innerWidth <= 1024) {
        this.mobileView = true;
        return;
      }
      this.mobileView = false;
    },
    onSezzleCheckout() {
      // validate payment checkbox
      if (!this.paymentCheckbox) {
        this.errors.paymentAgreement = true;
        // this.processing = false;
        return;
      }
      this.errors.paymentAgreement = false;
      // this.processing = true;
    },
    async submitSezzlePayment(card) {
      this.processing = true;
      try {
        await this.postOrder({
          canRetry: false,
          sezzleCardData: card,
        });
        await this.completeOrder();
        this.step = "confirmation";
        this.processing = false;
      } catch (error) {
        this.showErrorDialog(error);
        this.processing = false;
      }
    },
    // Refund Protect
    handleRefundProtect() {
      // this.setRefunfProtectData({});
      this.updateRefundProtectQuote = true;
      this.$nextTick(() => {
        this.updateRefundProtectQuote = false;
      });
    },

    async postProtectedOrder() {
      // console.log("Store: refund protect data", this.refoundProtectData);
      // console.log("Post order");
      try {
        this.processing = true;
        await this.postOrder({ canRetry: true });
        this.processing = false;
        this.codeApplying = false;
      } catch (error) {
        this.processing = false;
        this.codeApplying = false;
        this.showErrorDialog(error);
      }
    },
  },
  watch: {
    mobileMenu: {
      handler(val) {
        const body = document.getElementsByTagName("html")[0];
        if (val) {
          body.style.overflowY = "hidden";
          return;
        }
        return body.removeAttribute("style");
      },
    },
    "errorDialog.show": {
      handler(val) {
        if (val) return;
        this.errorDialog.title = "";
        this.errorDialog.subline = null;
        this.errorDialog.source = null;

        // refresh component handler
        this.submitPayment = false;
        //
        this.processing = false;
      },
    },
    step: {
      handler() {
        this.initRefundProtect = false;
      },
    },
    // killerDeal: {
    //   handler(value) {
    //     if (value) return this.setRefunfProtectData({});
    //   },
    // },
  },
  async mounted() {
    // Stripe specific payment platform redirect handler
    let { payment_intent, payment_intent_client_secret } = this.$route.query;
    if (payment_intent_client_secret) {
      const ACCEPTABLE_STATUSES = ["succeeded", "processing"];
      console.log("Stripe payment intent id", payment_intent);
      // 1. Init Stripe
      const stripe = await loadStripe(this.stripeConfig.publishableKey, {
        stripeAccount: this.stripeConfig.accountId,
      });
      // 2. Get Stripe payment intent
      const { paymentIntent, error } = await stripe.retrievePaymentIntent(
        payment_intent_client_secret
      );
      // 3. Check payment intent status or handle error
      // what error?
      if (error) return this.showErrorDialog({ title: error.message });
      // Show payment step
      if (!ACCEPTABLE_STATUSES.includes(paymentIntent.status)) {
        this.showErrorDialog({
          title: this.$t("checkout_error_payment_declined"),
        });

        this.step = "payment";
        await this.getOrderDescribe();
        return;
      }

      // 4. Complete order
      console.log("Stripe payment intent", paymentIntent);
      this.step = "confirmation";
      try {
        await this.completeOrder(paymentIntent.id);
        this.pushDataLayerEvent("Conversion");
      } catch (error) {
        this.showErrorDialog(error);
      }
      return;
    }
    // PayU payment platform redirect handler
    let { payment_processor, payment_status, order_id } = this.$route.query;
    if (payment_processor && payment_processor === "PayU") {
      if (payment_status === "success") {
        try {
          let transaction = await this.confirmPayuTransaction(order_id);
          console.log("PayU transaction data", transaction);
          this.step = "confirmation";
          await this.completeOrder();
          this.pushDataLayerEvent("Conversion");
          return;
        } catch (error) {
          this.showErrorDialog(error);
        }
      }
      if (payment_status === "failure") {
        this.showErrorDialog({
          title: this.$t("checkout_error_payment_declined"),
        });
        await this.retryRequest("post-order");
        this.step = "payment";
      }
    }

    // Checkout default load
    try {
      await this.generateTicketsList();
      await this.getOrderDetails();
      this.isLoadedOrder = true;

      await this.getOrderDescribe();
      // this.step = "payment";
    } catch (error) {
      this.showErrorDialog(error);
    }
  },
  created() {
    window.addEventListener("resize", this.onScreenSizeChange);
    this.onScreenSizeChange();
  },
  destroyed() {
    window.removeEventListener("resize", this.onScreenSizeChange);
  },
  async beforeRouteEnter(to, from, next) {
    let region = to.params.region;

    next((vm) => {
      vm.setCheckoutRegion(region);
      // if (vm.$route.query.step) vm.step = vm.$route.query.step;
    });
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;

$header-height: 70px;
$mobile-header-height: 70px;

.checkout-view {
  background-color: #f2f1f1;
  color: #000000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .checkout-nav {
    position: sticky;
    top: 0;
    height: $header-height;
    width: 100%;
    background-color: #000000;
    z-index: $zindex-toolbar;

    padding-left: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: $mobile-view) {
      display: none;
    }
    .nav-logo {
      height: 35px;
    }
    .nav-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 50px;

      .nav-link {
        font-size: 16px;
        font-weight: 700;
        color: #808080;
        @include cursorPointer;
      }

      .is-active {
        color: #ffffff;
      }
      .material-icons {
        font-size: 20px;
        color: #808080;
        margin-left: 14px;
        margin-right: 10px;
        pointer-events: none;
      }
    }
  }
  .centered-logo {
    justify-content: center;
    padding-left: 0;
  }
  .promo-code-section {
    background-color: #ffffff;
    margin-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: none;
    @media screen and (max-width: $mobile-view) {
      display: grid;
      grid-template-columns: 1fr;
    }
    .show-code-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      height: 44px;
      margin-top: 12px;
      margin-bottom: 12px;
      border-radius: 30px;
      background-color: #f2f2f2;

      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      @include cursorPointer;
    }
  }
  .checkout-body {
    $step-padding: 124px;
    $el-padding-top: 70px;
    flex: 1;
    color: #000000;
    background-color: #ffffff;
    padding-top: $el-padding-top;
    padding-left: 70px;
    display: grid;
    grid-template-columns: minmax(0, calc(642px + #{$step-padding})) max-content;
    align-items: flex-start;
    @media screen and (max-width: $mobile-view) {
      flex: none;
      background-color: inherit;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .checkout-mobile-nav {
      display: none;
      padding-top: 30px;
      // padding-bottom: 36px;
      background-color: #ffffff;
      @media screen and (max-width: $mobile-view) {
        display: flex;
        padding-left: 24px;
        padding-right: 24px;
      }
      .nav-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        .nav-link {
          color: #b7b7b7;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          @include cursorPointer;
          &.is-active {
            color: #000000;
          }
        }
        i {
          color: #b7b7b7;
          font-size: 14px;
          margin-left: 4px;
          margin-right: 4px;
          pointer-events: none;
        }
      }
    }
    .step-component {
      padding-right: $step-padding;
      @media screen and (max-width: $mobile-view) {
        padding-right: 0;
      }
    }
    .sticky-card {
      position: sticky;
      top: $header-height;
      @media screen and (max-width: $mobile-view) {
        display: none;
      }
    }
  }
  .checkout-policy {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 16px;
    font-weight: 700;
    background-color: #ffffff;
    padding-left: 70px;
    padding-bottom: 130px;
    @media screen and (max-width: $mobile-view) {
      height: 84px;
      padding-left: 24px;
      padding-bottom: 0;
      margin-bottom: 8px;
    }

    .material-icons {
      margin-left: 6px;
      font-size: 14px;
      color: #b9b9b9;
      @include cursorPointer;
    }
  }
  .checkout-controls {
    display: none;
    padding-right: 24px;
    padding-left: 24px;
    @media screen and (max-width: $mobile-view) {
      margin-top: 22px;
      display: grid;
      padding-bottom: 64px;
    }
    // button {
    //   height: 56px;
    //   border-radius: 32px;

    //   font-size: 14px;
    //   font-weight: 700;
    //   letter-spacing: 0.04em;
    // }
  }

  // mobile dropdown
  .mobile-header {
    height: $mobile-header-height;
    position: sticky;
    top: 0;
    display: none;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    background-color: #ffffff;
    border-bottom: 1px solid #d6d6d6;
    z-index: $zindex-page-sticky-el;
    &.is-collapsed {
      .collapse-button {
        i {
          transform: rotate(180deg);
        }
      }
    }
    &.is-disabled {
      pointer-events: none;
    }
    @media screen and (max-width: $mobile-view) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    .skeleton-line {
      height: 18px;
      position: relative;
      overflow: hidden;
      background-color: #e9e9e9;
      width: 80px;
      &::after {
        @include animatedLine("light");
      }
    }
    .collapse-button {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      @include cursorPointer;
      .cart-total {
        display: flex;
        flex-direction: column;
        text-align: end;
        .discount-amount {
          font-weight: 600;
          color: #cc092f;
          text-decoration: line-through;
        }
      }
    }
  }
  .dropdown-overlay {
    position: fixed;
    top: $mobile-header-height;
    height: 100%;
    width: 100%;
    background: rgba(34, 34, 34, 0.4);
    z-index: $zindex-page-overlay;
  }
  .dropdown-wrap {
    z-index: $zindex-toolbar;
    max-height: calc(100vh - #{$mobile-header-height});
    position: fixed;
    width: 100%;
    top: $mobile-header-height;
    background-color: #ffffff;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    // padding-bottom: 4px;
    .dropdown-body {
      padding-top: 26px;
      padding-left: 24px;
      padding-right: 24px;
      // overflow: scroll;
    }
    .dropdown-footer {
      // border-bottom: 1px solid rgba(34, 34, 34, 0.1);
      // box-shadow: 0px -3px 12px 0px rgba(0, 0, 0, 0.15);
      // position: sticky;
      // bottom: 0;
      padding: 8px 24px;
      background-color: #ffffff;
      .line-discount {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        height: 18px;
        color: #cc092f;
        text-decoration: line-through;
        text-align: end;
      }
      .line-total {
        height: 22px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        @media screen and (max-width: $mobile-view) {
          font-size: 15px;
        }
      }
      .line-vat {
        font-size: 12px;
        line-height: 18px;
        color: #646464;
        height: 18px;
        text-align: end;
      }
    }
  }
  .payment-agreement {
    display: flex;
    padding-top: 32px;
  }
  .payment-agreement-mob {
    display: none;
    padding-top: 18px;
    padding-bottom: 38px;
    background-color: #ffffff;
    padding-left: 24px;
    padding-right: 24px;
    @media screen and (max-width: $mobile-view) {
      display: flex;
    }
  }

  .payment-agreement,
  .payment-agreement-mob {
    flex-direction: row;
    align-items: flex-start;
    &.has-error {
      .agreement-text {
        color: #ff0707;
        ::v-deep(a) {
          color: #ff0707 !important;
          font-weight: 600;
        }
      }
    }
    .agreement-text {
      margin-left: 14px;
      font-size: 12px;
      line-height: 18px;
      max-width: 290px;
      ::v-deep(a) {
        color: #979797 !important;
        text-decoration: none;
      }
    }
  }
  .promo-code-slot {
    margin-top: 32px;
  }
}
.policy-tooltip {
  padding: 16px;
  position: relative;
  .tooltip-close {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #d3d3d3;
    font-size: 14px;
    @include cursorPointer;
  }
  .tooltip-title {
    font-size: 16px;
    font-weight: 700;
  }
  .tooltip-body {
    font-size: 14px;
    line-height: 14px;
  }
}
</style>